<template>
  <div>
    <van-nav-bar :title="orderlist.carNo" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="padding-top:46px;">
      <div class="flx">
        <div>车型：<span style="font-weight: bold;">{{ orderlist.carModel }}</span></div>
        <div class="flxdiv">
          <van-icon @click="shownr = true" name="question-o" size="18px" style="margin-right: 4px;" />
          <span>档次：</span>
          <div class="dua" :class="topacv == 0 ? 'topbj' : ''" @click="setdc(0)">中低端</div>
          <div class="dua" :class="topacv == 1 ? 'topbj' : ''" style="margin-left: 10px;" @click="setdc(1)">高端</div>
        </div>
      </div>
      <div class="dinwei">
        <div style="position: relative;">
          <img class="cimg" v-if="active == 0" src="../../assets/img/chetou.png" alt />
          <img class="cimg" v-if="active == 1" src="../../assets/img/chewei.png" alt />
          <img class="cimg" v-if="active == 2" src="../../assets/img/zuo.png" alt />
          <img class="cimg" v-if="active == 3" src="../../assets/img/you.png" alt />
          <img class="cimg" v-if="active == 4" src="../../assets/img/chetou.png" alt />
          <img class="bimg" v-for="(item, idd) in tulist.mallPaintingInfoDOList" :key="idd" v-show="item.isOK"
            :src="item.locationUrl" alt />
        </div>
      </div>
      <van-tabs v-model="active" style="flex-basis: 20%;overflow: hidden;" @change="qiehuan">
        <van-tab :title="item.plocation" v-for="(item, idx) in list" :key="idx">
          <div class="dd">
            <div class="cdd" v-for="(it, index) in item.mallPaintingInfoDOList" :key="index" @click="tianjia(it)"
              :style="{ color: (it.isOK ? '#ef3085' : '#666'), background: (it.isOK ? '#fef7f7' : '#fff'), }">
              <span>{{ it.location }}</span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="flix">
        <div>油漆质量：</div>
        <div class="fs" @click="addyqzl(0, zlform.pt, '普通')">
          <div class="bjsdiv" :class="yqacv == 0 ? 'yqbj' : ''">普通</div>
          <div class="jins">￥{{ zlform.pt ? zlform.pt : 0 }}</div>
        </div>
        <div class="fs" @click="addyqzl(1, zlform.zz, '珍珠')">
          <div class="bjsdiv" :class="yqacv == 1 ? 'yqbj' : ''">珍珠</div>
          <div class="jins">￥{{ zlform.zz ? zlform.zz : 0 }}</div>
        </div>
        <div class="fs" @click="addyqzl(2, zlform.yw, '鹦鹉')">
          <div class="bjsdiv" :class="yqacv == 2 ? 'yqbj' : ''">鹦鹉</div>
          <div class="jins">￥{{ zlform.yw ? zlform.yw : 0 }}</div>
        </div>
      </div>
      <div class="xuan">
        <div class="h1">
          <span class="left">已选:</span>
          <span class="right">
            共
            <span style="color: red;">{{ num }}</span> 个标准面
          </span>
        </div>
        <div class="dd">
          <div class="odd" v-for="(it, index) in selected" :key="index">
            <div>{{ it.location }}</div>
            <span class="biaozhun">{{ it.standards }} 标准面</span>
            <!-- <span class="jian" @click="shanchu(it,index)">×</span> -->
            <img class="jian" @click="shanchu(it, index)" src="../../assets/img/guan.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div style="padding-left: 12px;font-size: 16px;margin-top: 10px; " v-if="form.details">商品详情：</div>
    <van-row class="msgHtmlBox ql-snow ql-editor" v-html="form.details"></van-row>
    <div style="height: 60px;"></div>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '70%' }">
      <div class="qi_h1">
        <img class="gaoduan" v-if="radio == 1" src="../../assets/img/gaoduan.jpg" alt />
        <img class="gaoduan" v-if="radio == 0" src="../../assets/img/biaozhun.jpg" alt />
        <div class="jiage">
          折扣价
          <span style="font-size: 13px;">￥</span>
          <span style="font-weight: bold;font-size: 20px;">{{ zhe }}</span>起
          <del class="zhe">￥{{ zong }}</del>
        </div>
      </div>
      <div class="xuan_h">选择油漆产品</div>
      <div class="xuanzhe">
        <div class="xuandd" v-for="(it, index) in chanlist" :key="index" @click="tohuan(it)"
          :class="it.cid == radio ? 'racitv' : ''">
          <div>
            <span class="left">{{ it.name }}</span>
            <del class="right" style="font-size: 12px;color: #666;">￥{{ it.price }}</del>
          </div>
          <div class="biaozhunc">￥{{ it.activeprice }}起/标准面</div>
        </div>
      </div>
      <div class="xuan_h" style="margin-top: 35px;">数量</div>
      <div class="mian_shu">{{ num }}个标准面</div>
      <van-goods-action>
        <van-goods-action-button type="danger" text="确认" @click="todingdan" />
      </van-goods-action>
    </van-popup>
    <van-goods-action>
      <van-goods-action-button type="danger" text="加入工单" :disabled="!price" @click="addProjectSubmit" />
    </van-goods-action>
    <van-popup v-model="shownr" class="tandiv" closeable>
      <div style="text-align: center;">【高端品牌】</div>
      <div style="margin-top: 5px;">奔驰、宝马、奥迪、路虎、捷豹、保时捷、沃尔沃、雷克萨斯、英菲尼迪、凯迪拉克、玛萨拉蒂、宾利、劳斯莱斯。</div>
    </van-popup>
    <!-- onClickButton -->
  </div>
</template>

<script>
import userModel from "../../api/user";
import { getDetailByOId, addProject, saveWorkInfo, yqprice, keysearch } from "../../api/check";
export default {
  components: {},
  data() {
    return {
      list: [],
      chanlist: [],
      shownr: false,
      cx: [
        '奔驰', '宝马', '奥迪', '路虎', '捷豹', '保时捷', '沃尔沃', '雷克萨斯', '英菲尼迪', '凯迪拉克', '玛萨拉蒂', '宾利', '劳斯莱斯'
      ],
      topacv: null,
      selected: [],
      orderlist: {},
      tableData1: [],
      selectProjectList: [],
      tulist: {},
      zlform: {},
      active: 0,
      jgform: {},
      bp_level: '',
      isok: false,
      price: null,
      num: 0,
      radio: 1,
      yqacv: null,
      token: "",
      zong: "",
      zhe: "",
      oid: "",
      paintPrice: "",
      form: {},
      show: false,
    };
  },
  methods: {
    //跳转 订单确认
    todingdan() {
      //   console.log("4656465");

      this.$router.push({
        path: "spray_order",
      });

      let list = {
        mallPaintingInfoDOList: this.selected,
        num: this.num,
        paintType: this.radio,
        paintPrice: this.paintPrice,
        orderTotal: this.num * this.paintPrice,
        fuwu: this.num * this.paintPrice,
      };
      sessionStorage.setItem("penqi", JSON.stringify(list));
    },
    setdc(type) {
      this.topacv = type;
      if (type == 0) {
        this.zlform = {
          pt: this.jgform.midpt,
          yw: this.jgform.midyw,
          zz: this.jgform.midzz,
        }
      } else {
        this.zlform = {
          pt: this.jgform.maxpt,
          yw: this.jgform.maxyw,
          zz: this.jgform.maxzz,
        }
      }
      this.yqacv = null;
    },
    addyqzl(type, price, name) {
      if (price == 0) {
        this.$toast("请先选择有价格的油漆质量!");
        return
      }
      this.yqacv = type;
      this.price = price;
      this.bp_level = name;
      for (var i in this.selectProjectList) {
        this.selectProjectList[i].standPrice = price;
        this.selectProjectList[i].bp_level = name;
      }
      console.log(this.selectProjectList)
    },
    getyqprice() {
      yqprice({ oid: this.oid })
        .then((e) => {
          if (e.code == 200) {
            this.jgform = e.data;
            if (this.orderlist.source) {
              this.zlform = {
                pt: this.jgform.pt,
                yw: this.jgform.yw,
                zz: this.jgform.zz,
              }
            } else {


            }
            if (this.orderlist.carBrand) {
              console.log(this.orderlist.carBrand.replace(/\s+/g, ""))
              for (var i in this.cx) {
                if (this.orderlist.carBrand.replace(/\s+/g, "") == this.cx[i]) {
                  this.zlform = {
                    pt: this.jgform.maxpt,
                    yw: this.jgform.maxyw,
                    zz: this.jgform.maxzz,
                  }
                  this.topacv = 1
                  return
                } else {
                  this.zlform = {
                    pt: this.jgform.midpt,
                    yw: this.jgform.midyw,
                    zz: this.jgform.midzz,
                  }
                  this.topacv = 0
                }

              }
            }
            console.log(e)
          }
        })
    },
    //添加
    tianjia(item) {
      if (!item.isOK) {
        item.isOK = true;
        item.groupId = "973";
        item.groupName = "钣喷";
        item.standPrice = this.price ? this.price : 0;
        item.bp_level = this.bp_level;
        this.selected.push(item);
      }
      var data = {
        counts: 1,
        groupId: 973,
        groupName: "钣喷",
        isFactioryName: 1,
        item: item.location + "喷漆",
        oid: this.orderlist.oid,
        standPrice: this.price ? this.price : 0,
      };
      addProject(data)
        .then((e) => {
          if (e.code == 200) {
            console.log(e);
            e.data["counts"] = item.standards.toString();
            e.data["bp_level"] = this.bp_level;
            this.selectProjectList.push(e.data);
            console.log(this.selectProjectList);
          }
        })
        .catch();
      console.log(this.selected);
      this.addbiaozhun();
    },
    //切换油漆产品
    tohuan(it) {
      //   console.log(it);
      this.radio = it.cid;
      this.paintPrice = it.activeprice;
      this.zong = this.num * it.price;
      this.zhe = this.num * it.activeprice;
    },
    //弹窗选择
    xuantanchuan() {
      if (this.num == 0) {
        this.$toast("请先选择喷漆位置！");
        return;
      }
      userModel
        .peiqiprice()
        .then((e) => {
          this.chanlist[0] = {
            cid: 1,
            name: "高端漆",
            price: e.data.hprice,
            activeprice: e.data.haprice,
          };
          this.chanlist[1] = {
            cid: 0,
            name: "标准漆",
            price: e.data.sprice,
            activeprice: e.data.saprice,
          };
          this.paintPrice = this.chanlist[0].activeprice;
          this.zong = this.num * this.chanlist[0].price;
          this.zhe = this.num * this.chanlist[0].activeprice;
          this.show = true;
        })
        .catch();
    },
    //计算标准面
    addbiaozhun() {
      var num = 0;
      this.selected.forEach((item, i) => {
        num += item.standards;
      });
      this.num = num;
      console.log(num);
    },
    //删除
    shanchu(item, idx) {
      item.isOK = false;
      console.log(item);
      this.selected.splice(idx, 1);
      this.selectProjectList.splice(idx, 1);
      this.addbiaozhun();
    },
    qiehuan(name, title) {
      console.log(name);
      this.tulist = this.list[name];
      console.log(title);
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      userModel
        .peiqilist()
        .then((e) => {
          loading.clear();
          this.list = e.data;
          this.tulist = e.data[0];
        })
        .catch(() => loading.clear());
    },
    xiangqin() {
      userModel
        .penqiprice()
        .then((e) => {
          this.form = e.data;
        })
        .catch();
    },
    back() {
      if (!this.isok) {
        // alert('走了这里')
        // history.go(-1)
        _this.setupWebViewJavascriptBridge((bridge) => {
          var navBack = bridge.callHandler(
            "navBack",
            null,
            function responseCallback(responseData) {
              // alert('走了这里' + responseData)
              return responseData;
            }
          );
        });
      } else {
        window.androidjs.onCallbackIntent("Finish");
      }
    },
    init() {
      var _this = this;
      getDetailByOId({ oid: _this.oid })
        .then((e) => {
          if (e.code == 200) {
            this.orderlist = e.data;
            this.getyqprice()
            this.tableData1 =
              e.data.orderItemList == null ? [] : e.data.orderItemList;
            console.log(this.tableData1);
          }
        })
        .catch();
    },
    //确定选择
    addProjectSubmit() {
      if (this.selectProjectList.length == 0) {
        this.$toast("请先选择项目!");
        return
      }
      if (this.yqacv == null) {
        this.$toast("请先选择油漆质量!");
        return
      }
      var les = this.selectProjectList.length;
      var num = 0;
      this.selectProjectList.forEach((v) => {
        var lis = {
          groupId: v.groupId,
          groupName: v.groupName,
          id: v.id,
          standPrice: v.standPrice,
        };
        // updatexiangmu(lis).then(e => {});
        if (this.tableData1 && this.tableData1.length > 0) {
          // let row = this.tableData1.find(k => k.id == v.id);
          let row = this.tableData1.find((k) => k.id == v.id);
          if (row) {
            return this.$message({
              message: `项目${row.itemName}已存在`,
              type: "error",
            });
          }
        }
        v.itemId = v.id;
        v.bp_level = v.bp_level;
        v.isCombo = 1;
        v.itemName = v.item;
        v.orderDispatchList = [];
        if (!this.tableData1) this.tableData1 = [];
        console.log(v);
        this.tableData1.push(v);
        num += 1;
      });
      if (num == this.selectProjectList.length) {
        this.addsaveWorkInfo();
      }

      console.log(num);
    },
    addsaveWorkInfo() {
      keysearch(this.tableData1).then((e) => {
        if (e.code == 200) {
          for (var i in e.data) {
            for (var j in this.tableData1) {

              if (e.data[i].itemId = this.tableData1[j].id) {
                this.tableData1[j].orderPartList = e.data[i].partList
              }
              for (var k in this.tableData1[j].orderPartList) {
                this.tableData1[j].orderPartList[k].counts = this.tableData1[j].orderPartList[k].partCount;
                this.tableData1[j].orderPartList[k].gid = this.tableData1[j].gid;
                this.tableData1[j].orderPartList[k].groupId = this.tableData1[j].groupId;
                this.tableData1[j].orderPartList[k].groupName = this.tableData1[j].groupName;
                this.tableData1[j].orderPartList[k].itemId = this.tableData1[j].itemId;
                this.tableData1[j].orderPartList[k].price = this.tableData1[j].orderPartList[k].partPrice;
              }
            }
          }
          console.log(e)
          let data = {
            oid: this.orderlist.oid,
            gid: this.orderlist.gid,
            orderType: "0",
            // receivableItemAmount: this.data.receivableItemAmount,
            receivablePartAmount: this.orderlist.receivablePartAmount,
            // amountReceivable: this.data.amountReceivable,
            orderItems: this.tableData1,
          };
          saveWorkInfo(data).then((res) => {
            if (res.code == 200) {
              window.androidjs.onCallbackIntent("MaintenanceOrderActivity");
              // window.androidjs.onCallbackIntent("Finish");
            }
          });
        }
      });
      return

    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.init();
              _this.onLoad();
              _this.xiangqin();
              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        _this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        _this.init();
        _this.onLoad();
        _this.xiangqin();
        console.log("andriod");
      }
    },
  },

  created() {
    function getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    }
    var search = window.location.search;
    this.search = window.location.href;
    this.oid = getSearchString("oid", search); //结果：18
    // this.init();
    // this.onLoad();
    // this.xiangqin();
    // console.log(this.oid);
    // return
    this.appSource();
  },
  activated() {
    console.log("执行了");
    this.show = false;
  },
};
</script>
<style>
.van-tab {
  width: 20%;
  flex-basis: 20% !important;
}

.ql-editor p img {
  width: 100%;
}
</style>
<style lang="less" scoped>
.cimg {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 207px;
}

.flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 12px;
  line-height: 35px;
  margin-top: 5px;

  .flxdiv {
    display: flex;
    align-items: center;

    .dua {
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      width: 52px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 13px;
    }

    .topbj {
      background: #ee0a24;
      color: #fff;
    }
  }
}

.flix {
  display: flex;
  font-size: 14px;
  padding: 12px 12px 0px 12px;
  text-align: center;
  border-top: 1px solid #f5f5f5;

  .fs {
    flex: 1;
    margin: 0 10px;

    .jins {
      font-size: 12px;
      color: #0026a1;
    }

    .bjsdiv {
      border: 1px solid #f5f5f5;
      border-radius: 4px;
    }

    .yqbj {
      color: #ea0909;
      background: #fdebeb;
    }
  }
}

.van-tabs__content {
  margin: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}

.dd {
  width: 100%;
  font-size: 13px;
  color: #666;
  margin-top: 15px;
  min-height: 45px;
}

.xuanzhe {
  width: 100%;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  overflow: hidden;
}

.xuan {
  overflow: hidden;
  margin-top: 15px;
  border-top: 1px solid #f5f5f5;
}

.tandiv {
  padding: 14px 12px;
  width: 240px;
  font-size: 14px;
}

.jia {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  line-height: 12px;
  border: 1.5px solid red;
  border-radius: 8px;
  color: red;
  display: inline-block;
  font-weight: bold;
}

.jian {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  // border-radius: 8px;
  display: inline-block;
  font-weight: bold;
}

.h1 {
  overflow: hidden;
  padding: 6px 12px 0 12px;
  font-size: 14px;
}

.cdd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 40px;
  line-height: 40px;
  width: 28%;
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.odd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 45px;
  line-height: 35px;
  width: 28%;
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.xuandd {
  position: relative;
  float: left;
  margin-left: 4%;
  height: 50px;
  width: 27%;
  font-size: 13px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 4px 8px;
}

.qi_h1 {
  position: relative;
  padding: 20px 12px 0px 12px;
}

.xuan_h {
  margin-top: 20px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: bold;
}

.jiage {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 140px;
  margin-bottom: 5px;
  color: #ee0a24;
  font-size: 14px;
}

.zhe {
  // margin-top: 5px;
  color: #666;
  font-size: 13px;
  // margin-left: 2px;
}

.gaoduan {
  width: 100px;
  height: 100px;
  vertical-align: text-bottom;
}

.biaozhun {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  transform: scale(0.8);
  color: mediumslateblue;
}

.mian_shu {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  font-size: 12px;
  display: inline-block;
  padding: 2px 12px;
  margin-left: 12px;
  background: #fef7f7;
  color: #ef3085;
}

.racitv {
  color: rgb(239, 48, 133);
  background: rgb(254, 247, 247);
}

.biaozhunc {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 11px;
  margin-left: 8px;
  margin-bottom: 4px;
  // transform: scale(0.8);
  // color: #666;
}

.cdd:nth-of-type(n + 4) {
  margin-top: 8px;
}

.odd:nth-of-type(n + 4) {
  margin-top: 8px;
}

.gang {
  font-size: 14px;
  color: #666;
}

.van-goods-action-button--last {
  border-radius: 20px;
}

.bimg {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
  height: 207px;
}

.dinwei {
  position: relative;
  height: 207px;
}

.van-cell {
  color: #323233;
  font-size: 14px;
}

.ql-editor {
  margin: 2px 15px;
  overflow: hidden;
  font-size: 14px;
  // margin-top: 15px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}

.ql-editor p img {
  width: 100%;
  outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox>>>img {
  width: 100%;
}

/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>